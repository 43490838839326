<!--
 * @Description: 添加收款、退款
 * @Author: 琢磨先生
 * @Date: 2022-07-04 01:06:27
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2025-01-10 15:50:21
-->
<template>
  <el-collapse>
    <el-collapse-item :name="1" title>
      <template #title>
        <div class="flex-row flex-between" style="width: 100%">
          <b>收款金额：¥{{ order.decimal_receipts_total_amount }}</b>
        </div>
        <el-button
          type="primary"
          link
          @click.stop="onAdd"
          v-power="'seller/v1/houseOrderReceipt/add'"
        >+收款/退款</el-button>
      </template>

      <el-table :data="order.receipts" border>
        <el-table-column label="收支" prop="in_out_text" width="100"></el-table-column>
        <el-table-column label="项目" prop="type_text" width="120"></el-table-column>
        <el-table-column label="支付方式" prop="payment_term_name" width="120"></el-table-column>
        <el-table-column label="金额" width="100">
          <template #default="scope">
            {{ scope.row.in_or_out == 1 ? "-" : "+"
            }}{{ scope.row.decimal_amount }}
          </template>
        </el-table-column>
        <el-table-column label="日期" prop="date" width="120"></el-table-column>
        <el-table-column label="状态/结果" prop="status_text" width="140">
          <template #default="scope">
            <el-link type="primary" :underline="false" v-if="scope.row.status == 5">
              {{ scope.row.status_text }}/{{
              scope.row.result_status_text
              }}
            </el-link>
            <el-link type="warning" :underline="false" v-else-if="scope.row.status == 2">
              {{ scope.row.status_text }} /
              {{ scope.row.result_status_text }}
            </el-link>
            <el-link :underline="false" v-else>
              {{ scope.row.status_text }} /
              {{ scope.row.result_status_text }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" width="220"></el-table-column>
        <el-table-column label="创建时间" prop="create_at" width="160"></el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template #default="scope">
            <el-button
              type="primary"
              link
              size="small"
              v-if="scope.row.pay_no && scope.row.in_or_out==1 && scope.row.status==5"
              @click="onViewDtl(scope.row.id)"
            >详情</el-button>
            <el-popconfirm
              title="确定要删除？"
              @confirm="onDelete(scope.row)"
              v-if="scope.row.boolDeletable && is_can_del"
            >
              <template #reference>
                <el-button link size="small" class="text-danger" icon="Delete"></el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-collapse-item>
  </el-collapse>

  <!-- 收退款弹框 -->
  <el-dialog
    top="10vh"
    title="收款/退款"
    draggable
    v-model="visibleDialog"
    width="700px"
    custom-class="dialog-receipt"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    @closed="onClosed"
  >
    <template #header>
      <el-tabs v-model="form.in_or_out" @tab-change="onInOutChange">
        <el-tab-pane label="收款" :name="0"></el-tab-pane>
        <el-tab-pane label="退款" :name="1"></el-tab-pane>
      </el-tabs>
    </template>
    <amount :order="order"></amount>

    <el-form :model="form" ref="form" :rules="rules" label-width="80px">
      <el-form-item label v-if="form.in_or_out == 1">
        <div class="refund_tab">
          <div
            :class="`tab ${form.return_way == 0 ? 'active' : ''}`"
            @click="form.return_way = 0"
          >线上原路退款</div>
          <div
            :class="`tab ${form.return_way == 1 ? 'active' : ''}`"
            @click="
              () => {
                form.return_way = 1;
                form.payment_term_id = '';
              }
            "
          >线下退款</div>
        </div>
      </el-form-item>

      <el-form-item label v-if="form.in_or_out == 1 && form.return_way == 0">
        <el-radio-group v-model="form.father_id" @change="radioFatherChange">
          <el-table :data="order.receipts.filter((o) => o.boolQRcodeReturnable)" border stripe>
            <el-table-column label="收款时间" width="180">
              <template #default="scope">
                <el-radio :label="scope.row.id">{{ scope.row.create_at }}</el-radio>
              </template>
            </el-table-column>
            <el-table-column label="收款金额" width="120">
              <template #default="scope">
                ￥{{ scope.row.decimal_amount }}({{
                scope.row.type_text
                }})
              </template>
            </el-table-column>
            <el-table-column label="可退金额" width="100">
              <template #default="scope">
                ￥{{
                scope.row.decimal_amount - scope.row.decimal_refund_amount
                }}
              </template>
            </el-table-column>
            <el-table-column label="备注" prop="remark" width="150"></el-table-column>
          </el-table>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="项目" prop="type">
        <el-radio-group v-model="form.type">
          <el-radio
            v-for="item in enums.receipt_types"
            :key="item.value"
            :label="item.value"
          >{{ item.text }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        label="支付方式"
        prop="payment_term_id"
        v-if="
          form.in_or_out == 0 || (form.in_or_out == 1 && form.return_way == 1)
        "
      >
        <el-select v-model="form.payment_term_id">
          <el-option
            v-for="item in pay_term_list"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="金额" prop="decimal_amount">
        <el-col :span="10">
          <el-input v-model="form.decimal_amount" placeholder="请输入金额"></el-input>
        </el-col>
        <div
          class="help-block"
          v-if="this.form.item && !this.form.item.is_defined"
        >单价：¥{{ this.form.item.decimal_unit_price }}</div>
      </el-form-item>
      <el-form-item label="日期" prop="date">
        <el-date-picker
          v-model="form.date"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          placeholder="选择日期时间"
          :disabled="form.in_or_out == 1 && form.return_way == 0"
          :disabled-date="(date) => date > new Date()"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-col :span="20">
          <el-input v-model="form.remark" type="textarea" placeholder="备注内容"></el-input>
        </el-col>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" :loading="saving" @click="onSubmit">确定</el-button>
    </template>
  </el-dialog>

  <!-- 收款二维码弹框 -->
  <el-dialog
    title="收款"
    v-model="visibleQRCode"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :append-to-body="true"
    @closed="onClosed"
  >
    <div class="qr_box">
      <div style="font-size: 18px">收款金额：￥{{ form.decimal_amount }}</div>
      <div style="margin-top: 20px">请联系客户尽快付款，超时后付款链接将关闭</div>
    </div>
    <div style="margin-top: 20px">复制图片/截图发送给客人，使用微信扫描二维码进行支付</div>
    <div class="qr_code">
      <el-image :src="qrCodeUrl"></el-image>
    </div>
  </el-dialog>

  <el-dialog title="查看详情" v-model="visibleDtl" width="800px" :close-on-click-modal="false">
    <div>
      <el-descriptions>
        <el-descriptions-item label="收支">{{model.in_out_text}}</el-descriptions-item>
        <el-descriptions-item label="项目">{{model.type_text}}</el-descriptions-item>
        <el-descriptions-item label="支付方式">{{model.payment_term_name}}</el-descriptions-item>
        <el-descriptions-item label="金额">￥{{model.decimal_amount}}</el-descriptions-item>
        <el-descriptions-item label="收款日期">{{model.date}}</el-descriptions-item>
        <el-descriptions-item label="状态/结果">
          <el-link
            :underline="false"
            type="primary"
            v-if="model.status!=10"
          >{{model.status_text}}{{model.result_status_text}}</el-link>
          <span v-else>{{model.status_text}}{{model.result_status_text}}</span>
        </el-descriptions-item>
        <el-descriptions-item label="备注">{{model.remark}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{model.create_at}}</el-descriptions-item>
      </el-descriptions>
      <el-divider>微信退款记录</el-divider>
      <el-table :data="model.refund_pay_list" border stripe>
        <el-table-column label="退款单号" prop="refund_no" width="200"></el-table-column>
        <el-table-column label="金额" prop="decimal_amount" width="100"></el-table-column>
        <el-table-column label="状态" prop="status_text" width="120"></el-table-column>
        <el-table-column label="备注" prop="remark" min-width="200"></el-table-column>
        <el-table-column label="操作" width="100" fixed="right">
          <template #default="scope">
            <el-button
              type="success"
              link
              icon="Refresh"
              size="small"
              :loading="saving_retry"
              v-if="model.in_or_out==1 && model.status==5"
              @click="onRefundRetry(scope.row)"
              v-power="'seller/v1/houseOrderReceipt/retry'"
            >重试</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template #footer>
      <el-button
        type="danger"
        v-if="model.in_or_out==1 && model.status==5"
        v-power="'seller/v1/houseOrderReceipt/cancel'"
        :loading="saving_retry"
        @click="onCancel"
      >撤销退款</el-button>
    </template>
  </el-dialog>
</template>

<script>
import Amount from "./order_amount.vue";
import QRCode from "qrcode";
import date_util from "@/utils/date_util";

export default {
  components: {
    Amount,
  },
  data() {
    return {
      //加载详情
      loadingDtl: false,

      visibleDialog: false,
      visibleQRCode: false,
      //查看详情弹框
      visibleDtl: false,
      //保存
      saving: false,
      //收款项
      pay_term_list: [],
      form: {},
      rules: {
        payment_term_id: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择", trigger: "blur" }],
        decimal_amount: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        date: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
      },
      //收款二维码地址
      code_url: "",
      //二维码图片显示地址
      qrCodeUrl: "",
      //详情
      model: {},
      //重试提交退款支付
      saving_retry: false,
      //可以删除
      is_can_del: false,
    };
  },
  props: ["order", "enums"],
  emits: ["close"],
  created() {
    this.$http.get("seller/v1/paymentTerm").then((res) => {
      if (res.code == 0) {
        this.paymentItems = res.data.filter((o) =>!o.is_stop);
        this.pay_term_list = this.paymentItems;
      }
    });
    this.is_can_del = this.$power("seller/v1/houseOrderReceipt/del");
  },
  methods: {
    /**
     * 添加
     */
    onAdd() {
      this.visibleDialog = true;
      this.form = {
        type: 0,
        in_or_out: 0,
        return_way: 1,
        father_id: "",
        date: date_util.formatDate(new Date()),
      };
    },
    /**
     * 收款、退款切换
     */
    onInOutChange(val) {
      if (val == 1) {
        this.pay_term_list = this.paymentItems.filter(
          (o) => o.code != "wxQrCodePay"
        );
      } else {
        this.pay_term_list = this.paymentItems;
      }
    },

    /**
     * 原路退回 点击选择
     */
    radioFatherChange(val) {
      var item = this.order.receipts.find((o) => o.id == val);
      this.form.type = item.type;
      this.form.decimal_amount =
        item.decimal_amount - item.decimal_refund_amount;
      this.form.payment_term_id = item.payment_term_id;
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.form.order_id = this.order.id;
      this.form.order_version = this.order.version;

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("seller/v1/houseOrderReceipt/add", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visibleDialog = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.update = true;
                this.form.decimal_amount = res.data.receipt.decimal_amount;
                if (res.data && res.data.code_url) {
                  this.code_url = res.data.code_url;
                  //显示二维码弹框
                  this.visibleQRCode = true;
                  QRCode.toDataURL(this.code_url, {
                    errorCorrectionLevel: "H",
                    width: 260,
                    height: 260,
                  })
                    .then((url) => {
                      this.qrCodeUrl = url;
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },

    /**
     * 删除记录
     */
    onDelete(item) {
      this.$http
        .post("seller/v1/houseOrderReceipt/del", {
          id: item.id,
          order_version: this.order.version,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.update = true;
          }
          this.onClosed();
        });
    },

    /**
     *
     */
    onClosed() {
      this.$emit("close", this.update);
    },

    /**
     * 查看详情
     */
    onViewDtl(id) {
      this.visibleDtl = true;
      this.$http.get("seller/v1/houseOrderReceipt/dtl?id=" + id).then((res) => {
        if (res.code == 0) {
          this.model = res.data;
        }
      });
    },

    /**
     * 申请重试退款
     */
    onRefundRetry(item) {
      this.$confirm("您确定要申请重试退款？", "温馨提示").then(() => {
        this.saving_retry = true;
        this.$http
          .get("seller/v1/houseOrderReceipt/retry?id=" + item.id)
          .then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
            }
            this.onViewDtl(item.receipt_id);
          })
          .finally(() => {
            this.saving_retry = false;
          });
      });
    },

    /**
     * 取消退款
     */
    onCancel() {
      this.$confirm("您确定要撤销退款？", "温馨提示").then(() => {
        this.saving_retry = true;
        this.$http
          .get("seller/v1/houseOrderReceipt/cancel?id=" + this.model.id)
          .then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
              this.visibleDtl = false;
              this.model = {};
              this.$emit("close", true);
            }
          })
          .finally(() => {
            this.saving_retry = false;
          });
      });
    },
  },
};
</script>

<style scoped>
.qr_code {
  display: flex;
  align-items: center;
  justify-content: center;
}

.refund_tab {
  display: flex;
  align-items: center;
}
.refund_tab .tab {
  height: 50px;
  padding: 0 50px;
  display: flex;
  align-items: center;
  border: 1px solid var(--el-border-color);
  cursor: pointer;
  margin-right: 20px;
}
.refund_tab .tab.active {
  border-color: #409eff;
}

/* 状态文字 */
.status_5 {
  color: #e6a23c;
}

.qr_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--el-color-info-light-9);
  padding: 25px 0;
}
</style>

<style>
.dialog-receipt .el-dialog__body {
  padding-top: 0;
}
</style>