<!--
 * @Description: 在线收款汇总
 * @Author: 琢磨先生
 * @Date: 2022-10-08 10:27:04
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2025-01-21 11:05:09
-->
<template>
  <div v-loading="loading"> 
    <div class="query">
      <div class="picker_box">
        <shop-select class="shop_select" @change="shopChange"></shop-select>
        <!-- <el-select
              v-model="query.ota_id"
              class="revenue_ota_select"
              placeholder="选择渠道"
              clearable
              @change="otaChange"
            >
              <el-option :label="item.name" :value="item.id" v-for="item in ota_list" :key="item.id"></el-option>
            </el-select> -->
        <ul>
          <li
            :class="i === dateIndex ? 'active' : ''"
            v-for="(item, i) in shortcuts"
            :key="item"
            @click="onDateLiTab(i)"
          >
            {{ item }}
          </li>
        </ul>
        <el-date-picker
          class="revenue_date_picker"
          v-model="query.dates"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
          type="daterange"
          unlink-panels
          @change="pickerChange"
          :clearable="false"
          start-placeholder
          end-placeholder
        />
      </div> 
    </div>
    <el-form :inline="true" style="margin-top: 10px;">
        <el-select v-model="query.in_or_out" placeholder="请选择收/退款" clearable @change="onChange">
            <el-option :value="0" label="收款"></el-option>
            <el-option :value="1" label="退款"></el-option>
        </el-select>
    </el-form>
    <div class="text-16-bold mt-20">在线收款汇总</div>
    <el-card class="box">
      <div class="statistics">
        <div class="col">
          <div class="icon"></div>
          <div class="data">
            <div class="label">
              净收款
              <el-tooltip
                class="box-item"
                effect="dark"
                content="总额 = 收款 - 退款"
                placement="top"
              >
                <el-icon>
                  <Warning />
                </el-icon>
              </el-tooltip>
            </div>
            <div class="amount">￥{{ total_model.sum.decimal_amount }}</div>
            <div class="percent"></div>
          </div>
        </div>
        <!-- <div class="col total">
            <div class="icon"></div>
            <div class="data">
              <div class="label">
                营业额
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="总营业额 = 房费 + 违约金 + 消费 - 平台管理费"
                  placement="top"
                >
                  <el-icon>
                    <Warning />
                  </el-icon>
                </el-tooltip>
              </div>
              <div class="amount">￥{{ total_model.total.decimal_amount }}</div>
              <div class="percent">
                房费￥{{ total_model.room_fee.decimal_amount }}、违约金￥{{
                  total_model.penal_sum.decimal_amount
                }}
                、客房消费￥{{ total_model.consume.decimal_amount }}、管理费￥{{
                  total_model.mgr_fee_sum.decimal_amount
                }}
              </div>
            </div>
          </div> -->
        <div class="col">
          <div class="icon"></div>
          <div class="data">
            <div class="label">收款</div>
            <div class="amount">￥{{ total_model.income.decimal_amount }}</div>
            <div class="percent"></div>
          </div>
        </div>
        <div class="col">
          <div class="icon"></div>
          <div class="data">
            <div class="label">
              退款
              <el-tooltip
                class="box-item"
                effect="dark"
                content="原路回退金额"
                placement="top"
              >
                <el-icon>
                  <Warning />
                </el-icon>
              </el-tooltip>
            </div>
            <div class="amount">￥{{ total_model.refund.decimal_amount }}</div>
            <div class="percent"></div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
  <el-card class="box data" v-loading="loading">
    <el-table
      :data="tableData.data"
      @sort-change="onSort"
      border
      row-key="id"
    >
    <el-table-column label="收支" prop="in_out_text" width="100"></el-table-column>
        <el-table-column label="项目" prop="type_text" width="120"></el-table-column>
        <el-table-column label="支付方式" prop="payment_term_name" width="120"></el-table-column>
        <el-table-column label="金额" width="100">
          <template #default="scope">
            {{ scope.row.in_or_out == 1 ? "-" : "+"
            }}{{ scope.row.decimal_amount }}
          </template>
        </el-table-column>
        <el-table-column label="日期" prop="date" width="120"></el-table-column>
        <el-table-column label="状态/结果" prop="status_text" width="140">
          <template #default="scope">
            <el-link type="primary" :underline="false" v-if="scope.row.status == 5">
              {{ scope.row.status_text }}/{{
              scope.row.result_status_text
              }}
            </el-link>
            <el-link type="warning" :underline="false" v-else-if="scope.row.status == 2">
              {{ scope.row.status_text }} /
              {{ scope.row.result_status_text }}
            </el-link>
            <el-link :underline="false" v-else>
              {{ scope.row.status_text }} /
              {{ scope.row.result_status_text }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" min-width="220"></el-table-column>
        <el-table-column label="订单号" prop="order.order_no" width="200"></el-table-column>
        <el-table-column label="客户" prop="order.contact_name" width="120"></el-table-column>
        <el-table-column label="客户手机" prop="order.mobile_phone" width="150"></el-table-column>
        <el-table-column label="创建时间" prop="create_at" width="160"></el-table-column>
</el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
      v-if="tableData.counts > 0"
    ></el-pagination>
  </el-card>
</template>

<script>
import date_util from "@/utils/date_util";
import ShopSelect from "@/views/components/shop_select.vue";
import common_api from "@/http/common_api";
export default {
  components: {
    ShopSelect,
  },
  data() {
    return {
      dateIndex: "",
      loading: false,
      // dateList: [],
      shortcuts: ["昨日", "今日", "上周", "本周", "上月", "本月"],
      //总计对象
      total_model: {
        income: { decimal_amount: 0, percentage: 0 },
        refund: { decimal_amount: 0, percentage: 0 },
        sum: { decimal_amount: 0, percentage: 0 },
      },
      //查询参数
      query: {
        ota_id: "",
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      ota_list: [],
      tableData: {
        counts: 0,
      },
    };
  },
  created() {
    common_api.get_enums().then(res=>{
      if(res.code === 0){
        console.log(res.data);
      }
    })
    //   ota_api.get_otas().then((res) => {
    //     if (res.code == 0) {
    //       this.ota_list = res.data;
    //     }
    //   });
    this.onDateLiTab(5);
  },
  methods: {
    /**
     * 快速日期选择切换
     */
    onDateLiTab(i) {
      this.dateIndex = i;
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth();
      var day = date.getDate();
      date = new Date(year, month, day);
      //默认当前月
      var start = new Date(year, month, 1);
      var end = new Date(year, month, day);
      var weekday = date.getDay();
      if (i == 0) {
        //昨日
        start = new Date(date.getTime() - 3600 * 24 * 1000);
        end = start;
      }
      if (i == 1) {
        //今日
        start = end;
      }
      if (i == 2) {
        //上周
        start = new Date(new Date(date).setDate(date.getDate() - weekday - 6));
        end = new Date(new Date(date).setDate(date.getDate() - weekday));
      }
      if (i == 3) {
        //本周
        start = new Date(new Date(date).setDate(date.getDate() - weekday + 1));
      }
      if (i == 4) {
        //上个月
        var x = new Date(new Date(start).setMonth(start.getMonth() - 1));
        end = new Date(new Date(start).setDate(new Date(start).getDate() - 1));
        start = x;
      }

      this.query.dates = [
        date_util.formatDate(start),
        date_util.formatDate(end),
      ];
      this.loadData();
    },

    /**
     * 日期选择更改
     */
    pickerChange() {
      this.dateIndex = "";
      this.loadData();
    },

    // /**
    //  * 渠道选择
    //  */
    // otaChange() {
    //   this.loadData();
    // },

    /**
     * 门店选择
     * @param {*} val
     */
    shopChange(val) {
      this.query.shop_id = val;
      this.loadData();
    },

    /**
     * 参数变更 查询
     */
    onChange(){
      this.loadData();
    },

    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.query = Object.assign({}, this.query);
      this.query.start_date = this.query.dates[0];
      this.query.end_date = this.query.dates[1];
      this.$http
        .post("seller/v1/analysis/receipt/statistics", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.total_model = res.data;
          }
        })
        .finally(() => (this.loading = false));
        this.loadList();
    },

    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadList();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadList();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadList();
    },

    loadList() {
      this.$http
        .post("seller/v1/analysis/receipt/list", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.text-16-bold {
  font-size: 16px;
  font-weight: 700;
}
.mt-20 {
  margin-top: 20px;
}
.query {
  display: flex;
  align-items: center;
}
.picker_box {
  display: flex;
  background: white;
  align-items: center;
  border-radius: var(--el-border-radius-base);
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color));
  overflow: hidden;
  height: 32px;
}
.picker_box ul {
  list-style: none;
  display: flex;
  align-items: center;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  height: 100%;
  border-right: 1px solid var(--el-border-color);
}
.picker_box ul li {
  list-style: none;
  padding: 0 10px;
  cursor: pointer;
}
.picker_box ul li:hover {
  color: #409eff;
}
.picker_box ul li.active {
  font-weight: 600;
  color: #409eff;
}

.statistics {
  display: flex;
  align-items: center;
}

/* 概览数据 */
.statistics .col {
  display: flex;
  align-items: center;
  height: 90px;
  position: relative;
  width: 19%;
  padding-left: 20px;
}
.statistics .col::after {
  position: absolute;
  content: "";
  display: block;
  width: 1px;
  background: var(--el-border-color);
  height: 40px;
  right: 0;
}
.statistics .col:last-child:after {
  background: none;
}
.statistics .col.total {
  flex-grow: 1;
}
.statistics .col .data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.statistics .col .data .label {
  color: #999;
  display: flex;
  align-items: center;
}
.statistics .col .data .label .el-icon {
  margin-left: 5px;
}
.statistics .col .data .amount {
  font-size: 26px;
  font-weight: 700;
}
.statistics .col .data .percent {
  font-size: 12px;
  color: #999;
  line-height: 12px;
  height: 12px;
}

.block .header {
  padding: 20px 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}

/* 图表 */
.block.chart .body {
  background: white;
  border-radius: 5px;
  height: 420px;
}
.block.chart .body .pie {
  height: 100%;
}
</style>
<style>
.revenue_date_picker.el-input__wrapper,
.revenue_ota_select .el-input__wrapper,
.shop_select .el-input__wrapper {
  box-shadow: none !important;
  border-radius: 0;
}
</style>
