<!--
 * @Description: 选择房源列表
 * @Author: 琢磨先生
 * @Date: 2023-08-23 12:28:59
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2025-01-08 09:56:49
-->
<template>
  <el-table
    ref="houseTable"
    :data="tableData"
    border
    stripe
    row-key="id"
    @selection-change="selectionChange"
  >
    <el-table-column
      label
      reserve-selection
      type="selection"
      width="50"
    ></el-table-column>
    <el-table-column label="编号" width="80" prop="id"></el-table-column>
    <!-- <el-table-column label="" width="90">
        <template #default="scope">
            <el-image fit="cover" :src="scope.row.logo_url"  style="width: 60px; height: 60px"></el-image>
        </template>
    </el-table-column>-->
    <el-table-column
      label="标题名称"
      prop="name"
      min-width="200"
    ></el-table-column>
    <el-table-column label="关联房间" min-width="200">
      <template #default="scope">
        <el-tag
          size="small"
          v-for="item in scope.row.stores"
          :key="item.id"
          style="margin-right: 10px"
          >{{ item.house_no }}</el-tag
        >
      </template>
    </el-table-column>
    <el-table-column label="状态" width="90">
      <template #default="scope">
        <el-tag
          :type="
            scope.row.status == 0
              ? 'info'
              : scope.row.status == 5
              ? ''
              : scope.row.status == 10
              ? 'success'
              : scope.row.status == 15
              ? 'danger'
              : ''
          "
          >{{ scope.row.status_text }}</el-tag
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      tableData: [],
    };
  },
  emits: ["change"],
  props: ["ids", "all"],
  watch: {
    ids: {
      handler() {
        if (this.tableData.length > 0) {
          this.tableData.forEach((node) => {
            if (this.ids.findIndex((o) => o == node.id) > -1) {
              this.$refs.houseTable.toggleRowSelection(node, true);
            }
          });
        }
      },
      immediate: true,
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载门店下的所有房源
     */
    loadData() {
      this.$http
        .get("seller/v1/house/all_base?isAll=" + this.all)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        });
    },

    /**
     * 选择更改
     */
    selectionChange(nodes) {
      this.$emit(
        "change",
        nodes.map((x) => {
          return {
            id: x.id,
            name: x.name,
          };
        })
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
