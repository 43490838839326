<!--
 * @Description: 订单详情
 * @Author: 琢磨先生
 * @Date: 2022-07-01 02:33:35
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2025-01-10 16:02:33
-->
<template>
  <el-drawer
    title="订单详情"
    v-model="visibleDrawer"
    direction="rtl"
    size="960px"
    @closed="onClosed"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    custom-class="order"
  >
    <template #header>
      <el-tabs v-model="tabActive">
        <el-tab-pane label="订单详情" :name="0"></el-tab-pane>
        <el-tab-pane label="操作日志" :name="1"></el-tab-pane>
      </el-tabs>
    </template>
    <el-scrollbar>
      <div v-loading="loading">
        <div v-if="tabActive == 0">
          <el-row>
            <el-col :span="16">
              <b class="name"
                >{{ order.contact_name }} {{ order.mobile_phone }}</b
              >
              <span v-if="order.mobile_phone_caller"
                >({{ order.mobile_phone_caller }})</span
              >
              <div style="margin-top: 10px">
                <el-tag
                  :type="
                    order.status == 10
                      ? 'warning'
                      : order.status == 15
                      ? ''
                      : 'info'
                  "
                  >{{ order.status_text }}</el-tag
                >
              </div>
            </el-col>
            <el-col :span="8" style="text-align: right">
              <el-tag>{{ order.ota_name }}</el-tag>
              <el-tag style="margin-left: 10px">{{ order.type_text }}</el-tag>
            </el-col>
          </el-row>
          <amount :order="order"></amount>
          <div class="br-dashed"></div>
          <div class="flex-row flex-between">
            <div class="text-16-bold">
              房间信息: ¥{{ order.decimal_amount }}
            </div>
          </div>
          <div
            class="mt-16 house"
            v-for="item in order.order_stores"
            :key="item.id"
          >
            <el-row>
              <el-col :span="13">
                <div class="text-14-bold">
                  {{ item.house_no }}({{ item.store.huxing_name }}、{{
                    item.store.shop_name
                  }})
                </div>
                <div class="mt-8">
                  {{ item.checkin_date }} 至 {{ item.checkout_date }}，{{
                    item.days
                  }}晚
                </div>
              </el-col>
              <el-col :span="4">
                <div class="flex-column flex-center" style="height: 100%">
                  <el-button link type="primary" @click="onResident(item)">
                    入住人({{ item.residents ? item.residents.length : 0 }})
                  </el-button>
                </div>
              </el-col>
              <el-col :span="4">
                <el-popover
                  v-if="item.status != 50"
                  placement="bottom"
                  width="500px"
                  trigger="hover"
                  popper-class="order-prices"
                >
                  <template #reference>
                    <div class="h-amount">¥{{ item.decimal_amount }}</div>
                  </template>
                  <template #default>
                    <!-- <div style="max-height:400px;"> -->
                    <el-scrollbar max-height="300px">
                      <div class="date_price">
                        <div
                          :class="`item ${item.is_invalid ? 'invalid' : ''}`"
                          v-for="item in item.datePriceList"
                          :key="item.id"
                        >
                          <div class="date">{{ item.date }}</div>
                          <div class="money">¥{{ item.decimal_price }}</div>
                        </div>
                      </div>
                    </el-scrollbar>
                    <!-- </div> -->
                  </template>
                </el-popover>
                <div style="text-align: right" v-if="item.status == 50">
                  <div>违约金：￥{{ item.decimal_break_amount }}</div>
                  <div style="text-decoration: line-through; color: #a8abb2">
                    ￥0.00
                  </div>
                </div>
              </el-col>
              <el-col :span="1"></el-col>
              <el-col :span="2">
                <div class="flex-column flex-center" style="height: 100%">
                  <el-tag
                    :type="
                      item.status == 10
                        ? ''
                        : item.status == 20
                        ? 'warning'
                        : item.status == 30
                        ? 'success'
                        : 'info'
                    "
                    >{{ item.status_text }}</el-tag
                  >
                </div>
              </el-col>
            </el-row>
          </div>

          <div class="mt-30">
            <living-expenses
              :order="order"
              :enums="enums"
              @close="closedBack"
            ></living-expenses>
            <div class="dashed"></div>
            <consumption
              :order="order"
              :enums="enums"
              @success="loadDtl"
            ></consumption>
            <div class="dashed"></div>
            <receipt
              :order="order"
              :enums="enums"
              @close="closedBack"
            ></receipt>
          </div>
          <el-alert type="info" :closable="false" title class="mt-16">
            <div class="flex-row">
              <div>订单备注：</div>
              <div
                class="flex-grow"
                style="white-space: pre-wrap; text-align: left"
              >
                {{ order.remark }}
              </div>
            </div>
            <div style="margin-top: 10px; width: 100%" class="flex-row">
              <div>操作备注：</div>
              <div
                style="
                  white-space: pre-wrap;
                  line-height: 24px;
                  flex-grow: 1;
                  width: 1px;
                "
              >
                {{ order.operate_remark }}
              </div>
            </div>
          </el-alert>
          <div class="mt-8">订单号：{{ order.order_no }}</div>
        </div>
        <div v-if="tabActive == 1">
          <dtl-log :logs="order.logs"></dtl-log>
        </div>
      </div>
    </el-scrollbar>
    <template #footer v-if="tabActive == 0">
      <div class="flex-row flex-between">
        <el-dropdown>
          <el-button>
            更多操作
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <cancel :order="order" @close="loadDtl"></cancel>
              <!-- <Restore :order="order" @close="loadDtl"></Restore> -->
              <undo-check-in :order="order" @close="loadDtl"></undo-check-in>
              <undo-check-out :order="order" @close="loadDtl"></undo-check-out>
              <split :order="order" @close="loadDtl"></split>
              <confirm
                :order="order"
                @close="loadDtl"
                v-if="order.boolConfirmable"
              ></confirm>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <div style="display: flex; align-items: center">
          <order-update
            v-if="order.boolUpdatable"
            :order="order"
            @success="updateSuccess"
          ></order-update>
          <check-in
            :order="order"
            @close="loadDtl"
            v-if="order.boolAvailable"
          ></check-in>
          <check-out
            :order="order"
            @close="loadDtl"
            v-if="order.boolUndoAvailable"
          ></check-out>
        </div>
        <!-- <settle
          :order="order"
          @close="loadDtl"
          v-if="order.boolSettable"
        ></settle>-->
      </div>
    </template>
  </el-drawer>
  <!-- 入住人 -->
  <el-dialog
    title="入住人信息"
    v-model="visibleResident"
    width="750px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      :model="item"
      ref="resident_forms"
      v-for="(item, i) in residents"
      :key="item.id"
      :rules="rules"
      label-width="80px"
      label-position="top"
      :inline="true"
      class="form-residents"
    >
      <el-form-item label="姓名" prop="name">
        <el-input
          v-model="item.name"
          placeholder="请输入姓名"
          style="width: 120px"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="mobile_phone">
        <el-input
          v-model="item.mobile_phone"
          placeholder="手机号码"
          style="width: 140px"
        ></el-input>
      </el-form-item>
      <el-form-item label="证件类型">
        <el-select v-model="item.card_type" style="width: 100px">
          <el-option
            v-for="item in enums.card_types"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="证件号码" style="width: 210px">
        <el-input v-model="item.card_no" placeholder="证件号码"></el-input>
      </el-form-item>
      <el-form-item label="删除">
        <el-button
          type="primary"
          size="small"
          icon="Delete"
          link
          circle
          @click="residents.splice(i, 1)"
        ></el-button>
      </el-form-item>
    </el-form>

    <template #footer>
      <div class="flex-row flex-between">
        <el-button type="primary" link @click="onAddResident"
          >+添加入住人</el-button
        >

        <el-button type="primary" :loading="saving" @click="onSubmitResident"
          >完成</el-button
        >
      </div>
    </template>
  </el-dialog>
  <wx-paycode
    :code_url="code_url"
    :amount="qrCodeAmount"
    v-if="code_url"
  ></wx-paycode>
</template>

<script>
import Consumption from "./order_consumption.vue";
import common_api from "@/http/common_api";
import Receipt from "./order_receipt.vue";
import LivingExpenses from "./living_expenses.vue";
import Cancel from "./order_cancel.vue";
// import Restore from "./order_restore.vue";
import Amount from "./order_amount.vue";
import CheckIn from "./order_check_in.vue";
import UndoCheckIn from "./order_undo_check_in.vue";
import CheckOut from "./order_check_out.vue";
import UndoCheckOut from "./order_undo_check_out.vue";
import Confirm from "./order_confirm.vue";
import WxPaycode from "./qrcode/wx_qrcode.vue";
import OrderUpdate from "./update/order_update.vue";
import DtlLog from "./order_dtl_log.vue";
import Split from "./order_split.vue";

// import Settle from "./order_settle.vue";
export default {
  components: {
    Consumption,
    Receipt,
    LivingExpenses,
    Cancel,
    Amount,
    DtlLog,
    CheckIn,
    UndoCheckIn,
    CheckOut,
    UndoCheckOut,
    Confirm,
    WxPaycode,
    OrderUpdate,
    Split
  },
  data() {
    return {
      loading: false,
      visibleDrawer: false,
      visibleResident: false,
      saving: false,
      tabActive: 0,
      collapseActive: 0,
      enums: {},
      //订单详情
      order: {},
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        // card_no: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      //
      residents: [],
      //微信收款二维码地址
      code_url: "",
      qrCodeAmount: "",
    };
  },
  emits: ["close", "success"],
  props: ["order_id", "order_dtl"],
  watch: {
    order_id: {
      handler() {
        if (this.order_id) {
          this.visibleDrawer = true;
          this.order = {};
          this.loadDtl();
        }
      },
      immediate: true,
    },
    //添加订单成功后传入的订单详情
    order_dtl: {
      handler() {
        if (this.order_dtl) {
          this.code_url = this.order_dtl.code_url;
          this.qrCodeAmount = this.order_dtl.wx_pay_code_amount;
        }
      },
      immediate: true,
    },
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });

    // this.loadDtl();
  },
  methods: {
    /**
     * 加载详情
     */
    loadDtl() {
      this.loading = true;
      this.$http
        .get("seller/v1/houseorder/dtl?id=" + this.order_id)
        .then((res) => {
          this.loading = false;
          this.order = res.data;
        });
    },
    /**
     *
     */
    onClosed() {
      this.$emit("close");
    },
    /**
     * 打开入住人
     */
    onResident(item) {
      this.order_store = item;
      this.visibleResident = true;
      this.residents = JSON.parse(JSON.stringify(item.residents));
    },
    /**
     * 添加入住人
     */
    onAddResident() {
      this.residents.push({
        name: "",
        mobile_phone: "",
        card_type: 0,
        card_no: "",
      });
    },
    /**
     * 提交入住人
     */
    onSubmitResident() {
      var valid = true;
      for (let i = 0; i < this.$refs.resident_forms.length; i++) {
        this.$refs.resident_forms[i].validate((valid) => {
          if (!valid) {
            valid = false;
          }
        });
      }
      if (valid) {
        this.saving = true;
        this.$http
          .post("seller/v1/houseorder/resident/edit", {
            order_id: this.order.id,
            order_store_id: this.order_store.id,
            residents: this.residents,
          })
          .then((res) => {
            if (res.code == 0) {
              this.visibleResident = false;
              this.loadDtl();
            }

            this.saving = false;
          });
      }
    },

    /**
     *
     */
    closedBack(isChange) {
      if (isChange) {
        this.loadDtl();
      }
    },

    /**
     * 修改成功回调
     */
    updateSuccess() {
      this.loadDtl();
    },
  },
};
</script>

<style scoped>
.order .ota {
  border: 1px solid var(--el-border-color);
  color: var(--text-label-color);
  font-size: 14px;
  padding: 5px 10px;
}

.order .name {
  font-size: 16px;
}

/* .order .amount {
  font-size: 14px;
  margin-top: 20px;
} */
.order .text-14-bold {
  font-weight: bold;
  font-size: 14px;
}

.order .text-16-bold {
  font-size: 18px;
  font-weight: bold;
}

.order .mt-5 {
  margin-top: 5px;
}

.order .mt-8 {
  margin-top: 8px;
}

.order .mt-16 {
  margin-top: 16px;
}

.order .mb-16 {
  margin-bottom: 16px;
}

.order .mt-30 {
  margin-top: 30px;
}

/* .order .text-16-bold.blur {
  color: #053dc8;
}
.order .text-16-bold.orange {
  color: #fa0;
}
.order .text-16-bold.red {
  color: #f5222d;
} */
.order .text-12 {
  font-size: 12px;
}

.order .text-12-tip {
  font-size: 12px;
  color: #999;
}

.order .br-dashed {
  border-top: 1px dashed #e0e0e0;
  margin: 20px 0;
}

.order .dashed {
  border-top: 1px dashed #e0e0e0;
}

/* 房间信息 */
.order .house {
  border: 1px solid #e0e0e0;
  padding: 15px;
}

/* .order .house .house_item{
  margin-bottom: 20px;
} */

.order .house .h-amount {
  text-align: right;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.order-prices .date_price {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* row-gap: 20px; */
  /* column-gap: 20px; */
  border-top: 1px solid var(--el-border-color);
  border-left: 1px solid var(--el-border-color);
}

.order-prices .date_price .item {
  text-align: center;
  border-right: 1px solid var(--el-border-color);
  border-bottom: 1px solid var(--el-border-color);
  padding: 10px;
}

.order-prices .date_price .date {
  font-size: 12px;
}
.order-prices .date_price .money {
  /* font-weight: bold; */
}
.order-prices .date_price .item.invalid {
  color: var(--el-color-info-light-3);
}

.order-prices .date_price .item.invalid .money {
  text-decoration: line-through;
}
</style>







<style>
.form-residents .el-form-item {
  margin-right: 20px;
  /* margin-bottom: 0; */
}

.form-residents .el-form-item:last-child {
  margin-right: 0px;
  /* margin-bottom: 0; */
}

.form-residents .el-form-item__content {
  height: 40px;
}

.order .el-alert__content {
  width: 100%;
}

.order .el-alert .el-alert__description{
  font-size: 14px;
}

</style>