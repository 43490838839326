<!--
 * @Description: 原路回退审核
 * @Author: 琢磨先生
 * @Date: 2022-10-30 11:57:13
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2025-01-23 15:53:43
-->
<template>
  <el-card class="box data" v-loading="loading">
    <el-table :data="data_list" border>
      <el-table-column label="订单号" prop="order.order_no" width="200">
        <template #default="scope">
          <el-link
            :underline="false"
            type="primary"
            @click="onDtl(scope.row)"
            >{{ scope.row.order.order_no }}</el-link
          >
        </template>
      </el-table-column>
      <el-table-column
        label="渠道"
        prop="order.ota_name"
        width="140"
      ></el-table-column>
      <el-table-column
        label="客户"
        prop="order.contact_name"
        width="140"
      ></el-table-column>
      <el-table-column
        label="退款项目"
        prop="type_text"
        width="140"
      ></el-table-column>
      <el-table-column label="退款金额" width="140">
        <template #default="scope">￥{{ scope.row.decimal_amount }}</template>
      </el-table-column>
      <el-table-column
        label="备注"
        prop="remark"
        min-width="200"
      ></el-table-column>
      <el-table-column
        label="创建人"
        prop="emp.chs_name"
        width="120"
      ></el-table-column>
      <el-table-column
        label="创建时间"
        prop="create_at"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            @click="onOpenCheck(scope.row)"
            v-if="is_can_check"
            >审核</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </el-card>

  <el-dialog
    title="审核"
    v-model="dialogVisible"
    width="800px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
  >
    <el-descriptions v-if="current">
      <el-descriptions-item label="订单号：">
        <el-link :underline="false" type="primary" @click="onDtl(current)">{{
          current.order.order_no
        }}</el-link>
      </el-descriptions-item>
      <el-descriptions-item label="渠道">{{
        current.order.ota_name
      }}</el-descriptions-item>
      <el-descriptions-item label="客户">{{
        current.order.contact_name
      }}</el-descriptions-item>
      <el-descriptions-item label="金额"
        >￥{{ current.decimal_amount }}</el-descriptions-item
      >
      <el-descriptions-item label="创建人"
        >￥{{ current.emp.chs_name }}</el-descriptions-item
      >
      <el-descriptions-item label="创建时间"
        >￥{{ current.create_at }}</el-descriptions-item
      >
      <el-descriptions-item label="备注">{{
        current.remark
      }}</el-descriptions-item>
    </el-descriptions>
    <el-divider>结果</el-divider>
    <el-form :model="form" ref="form" :rules="rules" label-width="80px">
      <el-form-item label="结果" prop="check_result">
        <el-radio-group v-model="form.check_result">
          <el-radio :label="10">通过</el-radio>
          <el-radio :label="20">拒绝</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="结果备注">
        <el-col :span="20">
          <el-input
            v-model="form.check_remark"
            placeholder
            type="textarea"
          ></el-input>
        </el-col>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确 定</el-button
      >
    </template>
  </el-dialog>

  <order-dtl
    v-if="current_order_id"
    :order_id="current_order_id"
    @close="
      () => {
        current_order_id = null;
      }
    "
  ></order-dtl>
</template>

<script>
import OrderDtl from "@/views/trade/order/order_dtl";

export default {
  components: {
    OrderDtl,
  },
  data() {
    return {
      loading: false,
      saving: false,
      dialogVisible: false,
      data_list: [],
      current: null,
      current_order_id: null,
      form: {},
      rules: {},
    };
  },
  created() {
    this.loadData();
    this.is_can_check = this.$power("seller/v1/HouseOrderReceipt/check");
  },
  methods: {
    /**
     * 展开订单详情
     * @param {*} item
     */
    onDtl(item) {
      this.current_order_id = item.order_id;
    },

    /**
     *
     */
    loadData() {
      this.loading = true;
      this.$http
        .post("seller/v1/HouseOrderReceipt/check_pending")
        .then((res) => {
          if (res.code == 0) {
            this.data_list = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 打开审核弹框
     */
    onOpenCheck(item) {
      this.dialogVisible = true;
      this.current = item;
      this.form = {
        id: item.id,
        check_result: 10,
        order_id: item.order_id,
      };
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.$confirm("确定提交？", "温馨提示")
        .then(() => {
          this.saving = true;
          this.$http
            .post("seller/v1/HouseOrderReceipt/check", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.dialogVisible = false;
                this.loadData();
              }
            });
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style scoped></style>
